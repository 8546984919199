<template>
<form @submit.prevent="form_type == 'add' ? addItinerary() : updateItinerary()">
    <div class="card form-view">
        <div class="p-fluid">
            <div class="p-grid">
                <label class="p-col-fixed">Itineray name:</label>
                <div class="p-col">
                    <InputText id="itinerary_name" v-model="itinerary.itinerary_name" type="text" :class="{'p-invalid':v$.itinerary_name.$error}" autofocus />
                    <small v-if="v$.itinerary_name.$error" class="p-error">{{v$.itinerary_name.required.$message.replace('Value', 'Itinerary name')}}</small>
                </div>
                <label class="p-col-fixed">Itinerary title:</label>
                <div class="p-col">
                    <InputText id="itinerary_title" v-model="itinerary.itinerary_title" type="text" :class="{'p-invalid':v$.itinerary_title.$error}" autofocus />
                    <small v-if="v$.itinerary_title.$error" class="p-error">{{v$.itinerary_title.required.$message.replace('Value', 'Itinerary title')}}</small>
                </div>
            </div>

            <hr style="border: 0; border-top: 1px solid var(--surface-d);"/>
            <div class="p-grid">
                <div class="p-col">
                    <label>Area:</label>
                </div>
                <div class="p-col-fixed">
                    <Button label="Add More Area" @click="addMoreArea()" class="p-button-sm"></Button>
                </div>
            </div>
            <div class="p-grid" v-for="(area, index) of landingArea" :key="area.id">
                <div class="p-col">
                    <Dropdown v-model="area.landing_area" :options="landingAreaDropdown" optionLabel="province_name" placeholder="Select area" :class="{'p-invalid': v1$.$each.$response.$errors[index].landing_area.length == 1 && landingarea_error}">
                    </Dropdown>
                </div>
                <div class="p-col-fixed">
                    <label>No. of Day:</label>
                </div>
                <div class="p-col">
                    <InputNumber v-model="area.day" :min="0.5" :step="0.5" showButtons :class="{'p-invalid': v1$.$each.$response.$errors[index].day.length == 1 && landingarea_error}"/>
                </div>
                <div class="p-col-fixed">
                    <Button icon="pi pi-trash" class="p-button-rounded p-button-outlined p-button-danger small-datatable-button" @click="confirmDeleteLandingArea(area, index)" />
                </div>
            </div>

            <hr style="border: 0; border-top: 1px solid var(--surface-d); margin-top: 15px"/>
            <div class="p-grid">
                <div class="p-col">
                    <label>Optional tour:</label>
                </div>
                <div class="p-col-fixed">
                    <Button label="Add Optional Tour" @click="addMoreOptionalTour()" class="p-button-sm"></Button>
                </div>
            </div>
            <div class="p-grid" v-for="(tour, index) of optionalTour" :key="tour.id">
                <div class="p-col">
                    <InputText id="optionaltour_desc" v-model="tour.desc" type="text" :class="{'p-invalid': v_optionaltour$.$each.$response.$errors[index].desc.length == 1 && optionaltour_error}" />
                </div>
                <div class="p-col-fixed">
                    <Button icon="pi pi-trash" class="p-button-rounded p-button-outlined p-button-danger small-datatable-button" @click="confirmDeleteOptionalTour(tour, index)" />
                </div>
            </div>
        </div>
    </div>

    <div class="p-grid p-ml-2">
        <label class="p-col-fixed">Itineray detail:</label>
        <div class="p-col p-mr-3" style="text-align: right">
            <Button label="Add More" @click="addMoreDay()" class="p-button-sm"></Button>
        </div>
    </div>
    <div class="card form-view" v-for="(itinerary, index) of itinerary_detail" :key="itinerary.id">
        <div class="p-fluid">
            <div class="p-grid">
                <label class="p-col-fixed">{{'Day ' + (index+1) + ':'}}</label>
                <div class="p-col">
                    <InputText v-model="itinerary.itin_outline" type="text" Placeholder="Outline" />
                </div>
                <div class="p-col-fixed">
                    <Button icon="pi pi-trash" class="p-button-rounded p-button-outlined p-button-danger small-datatable-button" @click="confirmDeleteItineraryDetail(itinerary, index)" />
                </div>
            </div>
            <Editor v-model="itinerary.itin_detail"  editorStyle="height: 250px"> </Editor>
        </div>
    </div>

    <div class="card form-view" >
        <div class="p-fluid">
            <div class="p-grid">
                <div class="p-md-6 p-sm-6">
                    <label style="font-weight: 500">Inclusive of:</label>
                    <Editor v-model="itinerary_incl_excl.inclusive"  editorStyle="height: 250px"> </Editor>
                </div>
                <div class="p-md-6 p-sm-6">
                    <label style="font-weight: 500">Exclusive of:</label>
                    <Editor v-model="itinerary_incl_excl.exclusive"  editorStyle="height: 250px"> </Editor>
                </div>
            </div>
        </div>
    </div>

    <div v-if="form_type == 'add'">
        <Button label="Save" type="submit" class="p-button-success" icon="pi pi-save"></Button>
    </div>
    <div v-else>
        <Button label="Update" type="submit" class="p-button-warning" icon="pi pi-save"></Button>
    </div>
</form>
<ConfirmDialog></ConfirmDialog>
</template>

<script>
import { ref, computed, onMounted, reactive } from 'vue'
import { useConfirm } from "primevue/useconfirm";
import { useToast } from "primevue/usetoast";
import { useRoute } from 'vue-router';
import useVuelidate from '@vuelidate/core';
import { helpers, required } from '@vuelidate/validators';

import ItineraryService from '../service/ItineraryService';
import ProvinceService from '../service/CambodiaProvinceService';

export default {
    setup() {
        const confirm = useConfirm()
        const toast = useToast()
        const route = useRoute()
        let form_type = ref('add')
        let itinerary = reactive({itinerary_name: '', itinerary_title: ''})
        let itinerary_detail = ref([])
        const itineraryID = route.params.id;
        
        let landingArea = ref([])
        let landingAreaDropdown = ref([])
        const selectedLandingArea = ref()

        let optionalTour = ref([])

        let itinerary_incl_excl = reactive({})

        //On Create
        const itinService = ref(new ItineraryService())
        const provinceService = ref(new ProvinceService())

        onMounted(() => {
            initFormData()

            if (route.name == 'updateitinerary'){         
                form_type.value = 'update';
                getItineraryData()
            }

            getProvinces()
        })

        //Method
        const initFormData = () => {
            Object.assign(itinerary, {itinerary_name: '', itinerary_title: ''})
            Object.assign(itinerary_incl_excl, {id: '', inclusive: '', exclusive: ''})

            landingArea.value.length = 0
            addMoreArea()

            optionalTour.value.length = 0
            
            itinerary_detail.value.length = 0
            addMoreDay()
        }
        
        const addMoreDay = () => {
            itinerary_detail.value.push({id: '', itin_outline: '', itin_detail: ''})
        }

        const getItineraryData = () => {
            //Get Itinerary Name
            itinService.value.getItineraryById(itineraryID).then(data => {
                Object.assign(itinerary, data)
            })

            itinService.value.getLandingArea(itineraryID).then(data => {
                landingArea.value = data.map(item => {
                    return {
                        id: item.id,
                        landing_area: {province_name: item.landing_area},
                        day: item.day,
                        itinerary_id: item.itinerary_id
                    }
                })
            })

            itinService.value.getOptionalTour(itineraryID).then(data => {
                optionalTour.value = data
            })
            
            itinService.value.getItineraryDetailById(itineraryID).then(data => {
                itinerary_detail.value = data.itinData
                Object.assign(itinerary_incl_excl, data.itinInclusive[0])
            })
        }

        const addItinerary = () => {
            if(validateForm() && validateLandingArea()) {
                let landingAreaData = []
                landingArea.value.map(data => {
                    landingAreaData.push({id: data.id, landing_area: data.landing_area.province_name, day: data.day})
                })

                itinService.value.addItinerary(itinerary, landingAreaData, optionalTour.value, itinerary_detail.value, itinerary_incl_excl).then(data => {
                    if(!data.errorResponse) {
                        initFormData()
                        v$.value.$reset()
                        v1$.value.$reset()
                        v_optionaltour$.value.$reset()
                        toast.add({severity:'success', summary: 'Info', detail:'Data successfully added.', life: 3000});
                    } else {
                        toast.add({severity:'warn', summary: 'Add Data Error ' + data.status, detail: data.errorResponse, life: 5000});        
                    }
                })
            }
        }

        const updateItinerary = () => {
            if(validateForm() && validateLandingArea() && validateOptionalTour()) {
                let landingAreaData = []
                landingArea.value.map(data => {
                    landingAreaData.push({id: data.id, landing_area: data.landing_area.province_name, day: data.day})
                })

                itinService.value.updateItinerary(itineraryID, itinerary, landingAreaData, optionalTour.value, itinerary_detail.value, itinerary_incl_excl).then(data => {
                    if(!data.errorResponse) {
                        getItineraryData()
                        v$.value.$reset()
                        v1$.value.$reset()
                        v_optionaltour$.value.$reset()
                        toast.add({severity:'success', summary: 'Info', detail:'Data successfully updated.', life: 3000});
                    } else {
                        toast.add({severity:'warn', summary: 'Update Data Error ' + data.status, detail: data.errorResponse, life: 5000});        
                    }
                })
            }
        }

        const confirmDeleteItineraryDetail = (itinerary, index) => {
            confirm.require({
                message: 'Are you sure you want to delete this itinerary?',
                header: 'Delete Confirmation',
                icon: 'pi pi-info-circle',
                acceptClass: 'p-button-danger',
                accept: () => {
                    const itinerary_id = itinerary.id
                    if(itinerary_id) {
                        itinService.value.deleteItinerayDetail(itinerary_id).then(data => {
                            if(!data.errorResponse){
                                itinerary_detail.value = itinerary_detail.value.filter(val => val.id !== itinerary_id);
                            } else {
                                toast.add({severity:'warn', summary: 'Delete Error ' + data.status, detail: data.errorResponse, life: 5000});        
                            }
                        })
                    } else {
                        itinerary_detail.value.splice(index, 1)
                    }
                }
            });
        }
        
        //===================Landing Area===================
        const getProvinces = () => {
            provinceService.value.getProvinces().then(data => {
                landingAreaDropdown.value = data.map(item => {
                    return {
                        province_name: item.province_name
                    }
                })
            });
        }

        const addMoreArea = () => {
            landingArea.value.push({id: '', landing_area: '', day: null})
        }

        const confirmDeleteLandingArea = (area, index) => {
            confirm.require({
                message: 'Are you sure you want to delete this landing area?',
                header: 'Delete Confirmation',
                icon: 'pi pi-info-circle',
                acceptClass: 'p-button-danger',
                accept: () => {
                    const landingarea_id = area.id
                    if(landingarea_id) {
                        itinService.value.deleteLandingArea(landingarea_id).then(data => {
                            if(!data.errorResponse){
                                landingArea.value = landingArea.value.filter(val => val.id !== landingarea_id);
                            } else {
                                toast.add({severity:'warn', summary: 'Delete Error ' + data.status, detail: data.errorResponse, life: 5000});        
                            }
                        })
                    } else {
                        landingArea.value.splice(index, 1)
                    }
                }
            });
        }

        //===================Optional Tour==================
        const addMoreOptionalTour = () => {
            optionalTour.value.push({id: '', desc: ''})
        }

        const confirmDeleteOptionalTour = (tour, index) => {
            confirm.require({
                message: 'Are you sure you want to delete this optional tour?',
                header: 'Delete Confirmation',
                icon: 'pi pi-info-circle',
                acceptClass: 'p-button-danger',
                accept: () => {
                    const optour_id = tour.id
                    if(optour_id) {
                        itinService.value.deleteOptionalTour(optour_id).then(data => {
                            if(!data.errorResponse){
                                optionalTour.value = optionalTour.value.filter(val => val.id !== optour_id);
                            } else {
                                toast.add({severity:'warn', summary: 'Delete Error ' + data.status, detail: data.errorResponse, life: 5000});        
                            }
                        })
                    } else {
                        optionalTour.value.splice(index, 1)
                    }
                }
            });
        }

        
        //===================Form Validations==================
        const rules = computed(() => {
            return {
                itinerary_name: { required },
                itinerary_title: { required }
            }            
        })
        const v$ = useVuelidate(rules, itinerary)
        const validateForm = () => {
            v$.value.$validate();
            if(!v$.value.$error){
                return true
            } else {
                window.scrollTo(0,0)
                return false
            }
        }

        const area_rules = computed(() => {
            return {
                $each: helpers.forEach({
                    landing_area: {
                        required
                    },
                    day: {
                        required
                    },
                })
            }
        })
        const v1$ = useVuelidate(area_rules, landingArea)

        let landingarea_error = ref(false)
        const validateLandingArea = () => {
            for(let error of v1$.value.$each.$response.$errors) {
                if(error.landing_area.length > 0 || error.day.length > 0) {
                    landingarea_error.value = true
                    window.scrollTo(0,0)
                    return false
                }
            }
            landingarea_error.value = false
            return true
        }

        const optionaltour_rules = computed(() => {
            return {
                $each: helpers.forEach({
                    desc: {
                        required
                    }
                })
            }
        })
        const v_optionaltour$ = useVuelidate(optionaltour_rules, optionalTour)

        let optionaltour_error = ref(false)
        const validateOptionalTour = () => {
            for(let error of v_optionaltour$.value.$each.$response.$errors) {
                if(error.desc.length > 0) {
                    optionaltour_error.value = true
                    window.scrollTo(0,0)
                    return false
                }
            }
            optionaltour_error.value = false
            return true
        }

        return {
            form_type,

            v$,
            addMoreDay,
            itinerary_detail,
            itinerary,
            addItinerary,
            updateItinerary,
            confirmDeleteItineraryDetail,
            itinerary_incl_excl,

            v1$,
            landingarea_error,
            landingArea,
            landingAreaDropdown,
            selectedLandingArea,
            addMoreArea,
            confirmDeleteLandingArea,

            v_optionaltour$,
            optionaltour_error,
            optionalTour,
            addMoreOptionalTour,
            confirmDeleteOptionalTour,
        }
    },
}
</script>

<style lang="scss" scoped>
.form-view {
    label {
        width: auto;
    }
}
</style>