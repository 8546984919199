import axios from '../axios.instance'

export default class EmployeeService {
    async getItinerary(){
        try {
            const res = await axios.get('/itinerary/');
            return res.data;
        } catch (error) {
            return error.response.data
        }
    }

    async getItineraryDetail(id){
        try {
            const res = await axios.get('/itinerary/getitinerarydetail/', {params: { itinID: id }});
            return res.data;
        } catch (error) {
            return error.response.data
        }
    }

    //USE IN UPDATE FORM
    async getItineraryById(id){
        try {
            const res = await axios.get('/itinerary/get-itinerary/'+id);
            return res.data;
        } catch (error) {
            return error.response.data
        }
    }

    async getItineraryDetailById(id){
        try {
            const res = await axios.get('/itinerary/get-itinerary-detail/'+id);
            return res.data;
        } catch (error) {
            return error.response.data
        }
    }

    async getLandingArea(id){
        try {
            const res = await axios.get('/itinerary/get-itinerary-landingarea/'+id);
            return res.data;
        } catch (error) {
            return error.response.data
        }
    }

    async getOptionalTour(id){
        try {
            const res = await axios.get('/itinerary/get-itinerary-optionaltour/'+id);
            return res.data;
        } catch (error) {
            return error.response.data
        }
    }

    async addItinerary(itinerary, landingarea, optionaltour, itinerarydetail, itininclusive){
        try {
            if(itinerary){
                const res = await axios.post('/itinerary', {itinerary, landingarea, optionaltour, itinerarydetail, itininclusive})
                return res.data;
            }
        } catch (error) {
            return error.response.data
        }
    }

    async updateItinerary(id, itinerary, landingarea, optionaltour, itinerarydetail, itininclusive){
        try {
            if(id!=0){
                const res = await axios.put('/itinerary/'+id, {itinerary, landingarea, optionaltour, itinerarydetail, itininclusive})
                return res.data;
            }
        } catch (error) {
            return error.response.data
        }
    }

    async deleteItinerary(id){
        try {
            if(id != 0) {
                const res = await axios.delete('/itinerary/'+id)
                return res.data;
            }
        } catch (error) {
            return error.response.data
        }
    }

    async deleteLandingArea(id){
        try {
            if(id != 0) {
                const res = await axios.delete('/itinerary/delete-landingarea/'+id)
                return res.data;
            }
        } catch (error) {
            return error.response.data
        }
    }

    async deleteOptionalTour(id){
        try {
            if(id != 0) {
                const res = await axios.delete('/itinerary/delete-optionaltour/'+id)
                return res.data;
            }
        } catch (error) {
            return error.response.data
        }
    }

    async deleteItinerayDetail(id){
        try {
            if(id != 0) {
                const res = await axios.delete('/itinerary/delete-itinerarydetail/'+id)
                return res.data;
            }
        } catch (error) {
            return error.response.data
        }
    }
}